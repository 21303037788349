$height: 45px;
$border: 1px solid grey;
$radius: 5px;
$shadow: 0 0 10px rgba(black, 0.3);

:root {
    --main-text-color: black;
    --output-backgroud: white;
    --sub-main-color: #dcdcdc;
    --file-name-color: white;
}


@mixin border-top {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-bottom {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
}

.compiler-body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    overflow: auto;
}

.compiler-content {
    min-width: 60%;
    width: 1000px;
    max-width: 95%;
    min-height: 500px;
    margin-top: calc(8vh + 30px);
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.compiler-content > div {
    width: 100%;
    display: flex;
}

.compiler-top-buttons {
    height: $height;
    display: flex;
    justify-content: space-between;
}

.buttons, .right-buttons {
    display: flex;
    justify-content: flex-start;
}

.buttons > button {
    height: $height;
    width: $height;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: none;
    border-left: $border;
    border-top: $border;
    border-bottom: $border;
    transition: 0.2s background ease-in-out;
    color: var(--main-text-color);
}

.buttons > button:last-child {
    border-right: $border;
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
}

.buttons > button:first-child {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
}

.buttons > button:not(.js-icon) * {
    height: 50%;
    width: auto;
}

.buttons > button:hover {
    background: var(--sub-main-color);
}

.js-icon {
    height: 100%;
    width: 100%;
}

.run-row {
    height: 28px;
    border-radius: $radius;
    display: flex;
    align-items: center;
    gap: 10px;
}

.run-button {
    background: white;
    border-radius: 5px;
    color: #2eab03;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.2s background ease-in-out, 0.2s color ease-in-out;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #2eab03;
    padding: 0 8px;
    height: 100%;
}

.run-button:hover {
    background: #2eab03;
    color: white;
}

.run-button > svg {
    height: 25px;
    margin: 0;
    padding: 0;
}

.run-row > input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid grey;
    outline: none;
    font-size: 14px;
    font-weight: normal;
    padding-inline: 10px;
}

.run-row > input:focus {
    outline: 1px solid #2eab03;
    border: none;
}

.compiler-editor {
    box-shadow: $shadow;
    border-radius: $radius;
    overflow: hidden;
    flex-direction: column;
}

.compiler-top {
    width: 100%;
    height: 30px;
    background: var(--sub-main-color);
    display: flex;
    justify-content: space-between;
    @include border-top;
}

.files {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.files > span {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
}

.files > .current-file {
    @include border-top;
    background: var(--file-name-color);
    transition: 0.2s ease-in-out background,
    0.1s ease-in-out border-top-right-radius,
    0.1s ease-in-out border-top-left-radius;
    color: var(--main-text-color);
}

.files > button {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
    border: 1px solid #bebebe;
    border-radius: $radius;
    background: white;
}

.ace-wrapper {
    height: 400px;
    width: 100%;
}

.languages-list {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
}

.languages-list > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap: 10px;
    border: 2px solid black;
    padding: 10px;
    width: 40%;
    border-radius: 10px;
    cursor: pointer;
}

.languages-list > div > svg {
    width: 40px;
    height: 40px;
}

.terminal-wrapper {
    height: 300px;
    gap: 0;
    background: var(--sub-main-color);
    border-radius: $radius;
    box-shadow: $shadow;
    margin-bottom: 50px;
}

.terminal-wrapper > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.terminal-buttons {
    width: 50px;
    background: var(--sub-main-color);
    margin-top: 10px;
    border-bottom-left-radius: $radius;
}

.terminal-buttons > button {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.terminal-buttons > button svg {
    width: 80%;
    height: 60%;
    color: var(--main-text-color);
}

.terminal-content {
    width: 100%;
    background: #222;
    align-items: flex-start;
    padding: 10px;
    gap: 5px;
    background: var(--output-backgroud);
    color: var(--main-text-color);
    overflow: scroll;
}


.dark-mode {
    background: #363b45;
    --main-text-color: white;
    --output-backgroud: #222;
    --sub-main-color: #2f3028;
    --file-name-color: #7a7575;
}
