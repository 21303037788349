@use "../init/global" as *;

.container {
    background: white;
    margin-top: 110px;
    width: 67%;
    margin-inline: auto;
    padding: 32px;
    border-radius: 30px;
    box-sizing: border-box;

    h1 {
        text-align: center;
        font-size: 42px;
    }

    p {
        margin-top: 30px;
        font-size: 18px;
    }

    @include mobile {
        width: 95%;
        margin-top: 75px;

        h1 {
            font-size: 38px;
        }
    }
}

