@use "../../init/global" as *;


.upper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #368bb0;
  padding-bottom: 20px;
  margin-top: var(--navigation-height);
  padding-top: 25px;
}

.title {
  color: white;
  width: 50%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  ul {
    margin-top: 5px;
  }
  li:not(:last-of-type) {
    margin-bottom: 5px;
  }

  & > div {
    font-size: 18px;
    @include mobile {
    }
  }

  @include mobile {
    justify-content: center;
    width: calc(100% - 10px);
    margin-left: 10px;
  }
}

.container {
  width: 95%;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 50px;


  & > div {
    width: 42%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    background: #4a6a93;
    box-shadow: 0 0 10px rgba(black, 0.5);
    border-radius: 10px;
    padding: 15px;
    color: white;
  }

  @media (max-width: 1050px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;


    & > div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      width: 90%;
    }
  }

  h2 {
    font-size: 28px;
  }

  span {
    font-size: 16.5px;
  }

  button {
    outline: none;
    padding: 12px 12px;
    border: none;
    color: black;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(black, 0.3);
    font-size: 16px;
    transition: 0.2s background ease-in-out;
    margin-top: 15px;
    margin-bottom: 15px;

    @include mobile {
      margin-top: 12px;
      margin-bottom: 10px;
    }
  }

  button:hover {
    background: lightgrey;
  }

  img {
    width: 100px;
  }

}

.modeBody {
  display: flex;
  gap: 12px;
}

.v1v {
  width: 70px !important;
}

.singlePlayer {
  width: 52px !important;
}

.subTitle {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: bold;
}

.chooseLevelContainer .upper {
  padding-top: 40px;
  padding-bottom: 32px;
}

.levelsContainer {
  margin-top: 45px;
}


.inviteOrJoinMainContent {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  & > div > h3 {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 50px;

  }

  @include mobile {
    flex-direction: column;
    gap: 50px;
  }


}

.joinContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
    display: inline-block;
  }

  form {
    width: 80%;
    display: flex;
    flex-direction: column;

    @include mobile {
      width: 92%;
    }
  }

  input[type="text"] {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px black solid;
    padding-left: 10px;
    box-sizing: border-box;
    font-size: 20px;
    margin-top: 5px;
  }

  button {
    margin-top: 30px;
    width: 100%;
    border-radius: 20px;
    font-size: 20px;
    font-weight: bold;
  }


}

.inviteContainer {
  width: 100%;
}

.border {
    border: 4px solid #4a6a93;
    border-radius: 8px;
   box-shadow: 0 0 10px rgba(black, 0.5);
   background: #4a6a93;
}

.inviteOrJoinTitle {
  h1 {
    margin-bottom: -10px;
    margin-top: -15px;
  }

  margin-bottom: -20px;

}


.loading {
  width: 509px;
  border: 0;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f6e2b9;

}

.loading button {
  width: 130px;
  background: dodgerblue;
  color: white;
  border: 0;
  border-radius: 20px;
  font-weight: bold;
  font-size: 20px;
  padding-block: 13px;
  margin-top: 20px;

}


.loading span {
  color: black;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;

  @include mobile {
    font-size: 24px;
  }
}

.loading img {
    width: 500px;

  @include mobile {
    width: 350px;
  }
}
