@use "global" as *;

.navigationBar {
    position: fixed;
    top: 0;
    width: 100%;
    height: var(--navigation-height);
    background: #f0f0f0;
    display: flex;
    z-index: 200;
    justify-content: space-between;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

$borderGap: 8px;

.page {
    height: 75%;
    top: 10%;
    cursor: pointer;
    display: grid;
    place-content: center;
    transition: 0.2s ease-in transform;
    font-size: 18px;
    font-weight: bold;
    color: rgb(31, 31, 31);
    text-decoration: none !important;
    margin-top: $borderGap;
}

.page:not(.active) {
    transition: 0.3s ease-in-out scale;

    &::after {
        content: "";
        height: 2px;
        width: 0;
        background: black;
        transition: width 0.3s ease-out;
        margin-top: $borderGap;
        margin-inline: auto;
    }

    &:hover::after {
        width: 100%
    }

    &:hover {
        scale: 1.05;
    }
}

.active {
    transition: 90ms ease-in-out scale;

    &::after {
        content: "";
        height: 2px;
        width: 100%;
        background: black;
        margin-top: $borderGap;
    }
}



.profileImage, .sigmaIcon {
    height: calc(var(--navigation-height) * 0.8);
    width: calc(var(--navigation-height) * 0.8);
    cursor: pointer;
    margin: auto 10px auto 20px;
}

.sigmaIcon {
    border-radius: 50%;
}

.navMenu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    gap: 30px;

    img {
        height: 80%;
    }
}

.loginBtn {
    height: 65%;
    width: 95px;
    border-radius: 20px;
    background: white;
    border: 1px solid black;
    font-weight: bold;
    font-size: 16px;
}

.languageBtn {
    width: 30px;
    height: 30px;
    border-radius: 100%;
}

.rightSide {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.mobileMenu {
    display: none;
    margin-left: 20px;
    margin-block: auto;
    border: 0;
    background: inherit;
    padding: 0;
}

.mobileMenuContent {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: var(--navigation-height);
    animation: whiteBackground 0.3s forwards;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    gap: 30px;
}

@keyframes whiteBackground{
    0% {

    }
    100% {
        background-color: white;
    }

}

.mobileMenuContent > span {
    font-size: 24px;
    font-weight: bold;
    margin-top: 70px;
    margin-bottom: 10px;
}

.mobileMenuContent > div {
    display: flex;
    gap: 20px;
}

.mobileMenuContent > div > span {
    font-size: 18px;
    color: rgb(0, 0, 0, 0.7);
}


@media (max-width: 960px) {
    .navMenu {
        display: none;
    }

    .mobileMenu {
        display: flex;
        width: 45px;
        height: 45px;
        flex-direction: column;
        justify-content: center;
        gap: 8.5px;
    }

    .mobileMenu div {
        background: black;
        width: 100%;
        height: 2px;
        transition: transform 0.2s ease-in-out;
    }

    .mobileMenuOpen div:nth-of-type(2) {
        display: none;
    }

    .mobileMenuOpen div:nth-of-type(1) {
        transform: rotate(45deg) scale(0.83) translateY(10px);
    }

    .mobileMenuOpen div:nth-of-type(3) {
        transform: rotate(-45deg) scale(0.83) translateY(-8px);
    }
}

.coins {
    width: 55px;
    height: 55px;
}

.coinsText {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.plusCoins {
    margin-right: 18px;
    font-weight: 900;
    font-size: 22px;
    animation: fadeOut 8s forwards;
    color: #a69228;
    text-shadow: 2px 2px 4px #000000;
}

.mobileLink {
    text-decoration: none;
    font-size: 20px;
    color: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    gap: 12px;
}

.mobileLink img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    filter: invert(44%) sepia(79%) saturate(683%) hue-rotate(172deg) brightness(97%) contrast(104%);
}

.mobileLink:nth-of-type(1) img, .mobileLink:nth-of-type(2) img{
    width: 40px;
    height: 30px;
    transform: scaleY(1.33);
    margin-left: -10px;
    position: relative;
    left: 4px;
}


@keyframes fadeOut {
    0% {
        opacity: 0;
    }

    35% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}


.logo {
    display: flex;
    align-items: center;
    font-family: "Avenir Next", sans-serif;
    font-weight: bold;
    font-size: 19px;
    margin-right: 16px;

    span {
        margin-left: -6px;
    }

    img {
        margin-top: 9px;
        width: 80px;
        height: 80px;
        border-radius: 10px;
    }
}
