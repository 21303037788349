@use "../../init/global" as *;

.seekBar {
    width: 45%;
    height: 32px;
    background: #2a5273;
    border-radius: 30px;
    margin-top: 30px;
    margin-inline: auto;
    display: flex;
    justify-content: space-between;
    position: relative;

    @include mobile {
        width: 90%;
        position: fixed;
        bottom: 3vh;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-height: 450px) {
        display: none;
    }
}


.seekBar > div {
    width: 40px;
    height: 100%;
    background: orange;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.seekBar div > img {
    width: 50px;
    height: 50px;
    position: absolute;
    transition: left 1s ease-in-out;
}

.seekBar div:last-of-type > img{

}

.current {
    background: forestgreen !important;
}

@include mobile {
    .seekBar div > img {
        width: 52px;
        height: 52px;
    }

    .last img{
        margin-left: -80px;
    }
}


