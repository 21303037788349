@use "../../init/global" as *;

.wonLoadingScreen {
    background: dodgerblue;

    width: 100%;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;

    h3 {
        font-size: 34px;
        text-align: center;
    }

    img {
        margin-top: 50px;
        width: 330px;
        height: 330px;
    }

    @include mobile {
        h3 {
            font-size: 28px;
        }
    }
}


.podium {
    margin-inline: auto;
    margin-top: 140px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 340px;
    $border: 6px;

    & > div:nth-child(1) {
        background: rgba(252,217,14,255);
        order: 2;
        height: 100%;
    }

    & > div:nth-child(2) {
        background: rgba(99,186,102,255);
        order: 1;
        height: 77%;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border;
    }

    & > div:nth-child(3) {
        background: rgba(0,151,230,255);
        height: 60%;
        border-top-left-radius: 0;
        border-bottom-right-radius: $border;
        order: 3;
    }

    & > div:nth-child(4) {
        order: 4;
    }

    & > div:nth-child(5) {
        order: 5;
    }

    & > div {
        width: min(150px, 30vw);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        border-top-left-radius: $border;
        border-top-right-radius: $border;
        height: 50px;
        position: relative;

        & > span:nth-of-type(1) {
            font-size: 38px;
            color: white;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            user-select: none;
        }

        & > span:nth-of-type(2) {
            font-size: 20px;
            font-weight: 400;
            color: white;
            position: absolute;
            bottom: -50px;
            transform: translateY(-50%);
            user-select: none;
        }

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -90px;
            font-size: 24px;
            font-weight: 450;

            img {
                width: 50px;
                height: 50px;
            }
        }
    }
}



.finishContainer {
    margin-top: 60px;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    background: linear-gradient(#00a6ff, #314cab, #6b65c4);
    margin-inline: auto;
    min-height: calc(100vh - 90px);
    width: min(700px, 100vw);
    padding-block: 30px;

    & > h2 {
        font-size: 30px;
        font-family: Game-Bold, serif;
        -webkit-text-stroke: 2px #3e0080;

        @include mobile {
            font-size: 24px;
        }
    }

    .coins {
        display: flex;
        align-items: center;
        margin: -20px 22px 20px auto;

        span {
            font-weight: 700;
            font-size: 24px;
        }
        img {
            width: 35px;
            height: 35px;
            margin-left: 10px;
        }
    }
}


.buttonContainer {
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin-top: 70px;

}

.buttonContainer button {
    width: min(400px, 95vw);
    border: 0;
    background: dodgerblue;
    color: white;
    border-radius: 6px;
    font-weight: bold;
    font-size: 20px;
    padding: 10px;

}
