$borderRadius: 15px;
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    width: min(800px, 95vw);
    margin-inline: auto;
    margin-top: 120px;
    border-radius: $borderRadius;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    padding-bottom: 30px;
    gap: 20px;
}

.tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 50px;
    background: wheat;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    padding-block: 15px;
    border-bottom: 1.5px solid;

    span {
        font-size: 20px;
        cursor: pointer;
        margin-top: 5px;
        transition: font-weight 0.2s ease-in-out;

        &:not(.activeTab):hover {
            font-weight: 600;
        }
    }

    .inactiveTab, .activeTab {
        transition: 70ms ease-in-out;
    }

    .activeTab {
        font-weight: bold;
    }
}

.profile {
    position: relative;
    width: 95%;
}

.place {
    font-weight: bold !important;
    color: white;
    border-radius: 8px;
    background: dodgerblue;
    padding-inline: 8px;
    padding-block: 3px;
}

.stats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: min(350px, 90%);
        padding-bottom: 20px;
        padding-top: 15px;
        padding-inline: 5px;
        border-bottom: 2px solid;
    }

    .profileHead {
        justify-content: space-evenly;
        margin-bottom: 10px;
        background: wheat;
        padding: 2px 5px;
        border-radius: 10px;
        border: 1px solid;

        span {
            font-weight: bold;
            font-size: 23px;
            margin-bottom: 10px;
            margin-top: 5px;
        }

        button {
            border: none;
            background: none;
            border-radius: 50%;
            padding: 4px 3px 0;
            width: 70px;
        }

        img {
            height: 100%;
            width: 100%;
        }
    }

    span:not(.title) {
        font-size: 22px;
    }

    span:first-of-type {
        font-weight: 500;
    }

    span:last-of-type {
    }
}

.record {
    color: black;
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    background: orange;
    animation: pulse 2s infinite;
}

.players {
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 16px !important;
}

@keyframes pulse {
    0% {
        background: orange;
    }
    33% {
        background: #ff4e4e;
    }
    66% {
        background: #00ff00;
    }
    100% {
        background: orange;
    }
}

.buttons {
    $red: #e85656;
    $green: #2ea42e;
    $darker: 3%;
    position: absolute;
    width: 140px;
    right: 0;

    button {
        margin-bottom: 15px;
        width: 100%;
    }

    .signOut {
        background: $red;
        color: white;
        border-radius: 8px;
        font-weight: bold;
        padding: 10px 0;
        font-size: 15px;
        transition: 0.2s ease-in-out;
        border: 0;

        &:hover {
            background: darken($red, $darker);
            scale: 1.02;
        }
    }

    .greenBtn {
        background: $green;

        &:hover {
            background: darken($green, $darker);
        }
    }
}

.history {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    & > div {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        padding: 15px 20px;
        width: 600px;
        gap: 20px;

        &:hover {
            cursor: pointer;
        }

        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
        }

        span {
            font-size: 19px;
            font-weight: 500;
        }
    }

    b {
        font-size: 20px;
        font-weight: 600;
    }

    .win {
        color: black;
        font-weight: bold;
        font-size: 20px;
        background: #f6c446;
        padding: 3px;
        border-radius: 5px;
    }
}

.quickplay {
    border: 3px solid forestgreen;
}

.date {
    margin: 0 0 0 auto;
    font-size: 15px !important;
    color: #575656;
}

.modeContainer {
    & > h3 {
        border-bottom: 3px solid black;
        margin-bottom: 16px;
        margin-top: 50px;
        font-size: 24px;
    }
}
