.expandItemTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.expandItemTitle img {
    width: 23px;
    height: 23px;
    filter: invert(100%) sepia(0%) saturate(7496%) hue-rotate(327deg) brightness(107%) contrast(101%);
    cursor: pointer;
}

.expandItemTitle span {
    font-weight: bold;
}

.expandItem {
    display: flex;
    flex-direction: column;
}

.contentContainer {
    animation: expand 3s ease ;
    height: 35px;
    position: relative;
    top: 15px;
}

.arrow {
    transition: transform 0.2s ease;
}

.arrowOpen {
    transform: rotate(180deg);
}

@keyframes expand {
    0% {
        opacity: 0;
        max-height: 0;
    }
    40% {
        opacity: 1;
    }
    100% {
        max-height: 1000px;
    }
}
