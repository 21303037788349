
.finishContainer {
    & > img {
        width: min(450px, 90%);
        height: min(450px, 90%);
        margin-inline: auto;
        margin-top: 30px;
    }
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: min(300px, 90%);
    margin-inline: auto;
    margin-top: 16px;

}

.finishBtn {
    text-decoration: none;
    color: white;
    background: black;
    font-weight: bold;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
}

.newRecord {
    transform: scale(0);
    animation: grow 0.5s forwards;

    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 509px;
    gap: 10px;

    h2 {
        margin-top: -24px;
    }
}

@keyframes grow {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.recordLogo {
    width: 350px;
    height: 350px;
}

.closeRecord {
    width: 30px;
    height: 30px;
    margin-top: 10px;
    cursor: pointer;
    margin-right: auto;
}

.closeBtn {
    background: inherit;
    border: none;
    margin-right: auto;
    margin-top: -10px;
}
