@use '../../init/global' as *;


.questionLayout {
    margin-top: var(--navigation-height);
}

.topRow {
    box-sizing: border-box;
    width: 94%;
    height: 48px;
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    margin-inline: auto;
    gap: 25px;

    @include mobile {
        width: 98%;
        margin-top: 100px;
        margin-bottom: 30px;

        .topRowLogo {
            display: none;
        }

        .nextQuestion {
            display: none;
        }

        .questionList {
            display: none;
        }
    }
}

.topRowMobile {
    gap: 14px;
    display: none;

    @include mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        height: auto;
        margin-bottom: 16px;

        select {
            width: 100%;
        }

        .submitOnly {
            grid-column: 1/3;
        }
    }


}



.topRowMobile .questionList, .topRowLogo, .nextQuestion, .selectBox select, .watch, .submit, .draw, .solutionBtn, .questionListExam {
    border-radius: 5px;
}

.selectBox:hover .toolTip{
    visibility: visible;
}

.selectBox {
    position: relative;
}

.questionList, .topRowLogo, .nextQuestion, .selectBox select, .watch, .submit, .draw, .solutionBtn, .questionListExam {
    border: 0;
    padding: 0;
    height: 45px;
    padding-inline: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    color: white;
    font-size: 16.5px;
    font-weight: bold;
    box-shadow: 0 0 10px rgba(black, 0.5);
    transition: 0.3s ease-in-out;
    background: #295986;
    user-select: none;
    cursor: pointer;
    position: relative;

    @include mobile {
        padding-inline: 9px;
        gap: 4.5px;
    }

    &:hover {
        background: rgb(60, 115, 250);
    }

}

.topRowLogo {
    background: $main-color;

    img {
        height: 70%;
        width: auto;
        border-radius: 10px;
    }
}

.questionList, .nextQuestion, .watch, .draw, .solutionBtn, .questionListExam {
    cursor: pointer;
    img {
        height: 60%;
        width: auto;
    }
}

.watch {
    gap: 10px;
}




.codeEditor {
    width: calc(50% - 0px);
    min-width: 400px;
    height: calc(100vh - 170px);

    @include mobile {
        height: calc(100vh - 195px);
    }
}


.container1 {
    display: flex;
    flex-direction: row;
    gap: 50px;

    @include mobile {
        gap: 78px;
    }
}

.container1 > div:first-child {
    margin-left: auto;
}

.container1 > div:last-child {
    margin-right: auto;
}

.questionInfo {
    display: flex;
    flex-direction: column;
    color: white;
    width: calc(50% - 150px);
}

.questionJustInfo {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: -10px;
}

.submit {
    margin-left: auto;
    background: green;

    &:hover {
        background: #035e03;
    }

    @include mobile {
        margin-left: initial;

    }
}

.questionName {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    border-bottom: 1px solid white;
    text-align: center;
    padding-bottom: 7px;

    @include mobile {
        margin-top: 80px !important;
    }
}

.actionsButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-inline: 40px;
    gap: 20px;
}

.actionsButtonsContainer button {
    background: #416ee1;
    border: none;
    border-radius: 16px;
    color: white;
    font-family: inherit;
    font-size: 18px;
    overflow: hidden;
    padding: 0.75em 0.8em;
    min-width: 140px;
    font-weight: 600;
}

.actionsButtonsContainer button:disabled {
    background-color: #9eb5ee;
    cursor: default;
}

.exampleContainer {
    margin-top: 30px;
}

.exampleContainer > div > span {
    font-size: 18px;
    font-weight: 600;
}

.exampleContainer > div > div {
    background: #2a5273;
    border-radius: 8px;
    padding: 17px;
    margin-bottom: 25px;
    margin-top: 7px;
}

.letterSpacing {
    letter-spacing: 1.5px;
    white-space: pre-wrap;
}

.varName {
    color: #fd971f;
}



.questionDescription {
    font-size: 18px;
    margin-top: 0;
    white-space: pre-wrap;
}

@include mobile{

    .container1 {
        flex-direction: column;
        align-items: center;
        padding-inline: 10px;
    }

    .topRowComputer {
        display: none;
    }

    .questionJustInfo {
        margin-top: -60px;
    }

    .questionInfo {
        width: min(100%, 550px);
        order: 2;
    }

    .codeEditor {
        order: 3;
        margin-top: -130px;
    }

    .actionsButtonsContainer {
        order: 1;
        margin-top: 60px;
        margin-bottom: 30px;
    }

    .container1 > div:first-child {
        margin-left: initial;
        padding-left: initial;
        margin-right: initial;
    }

    .container1 > div:last-child {
        margin-right: initial;
        padding-right: initial;
        margin-left: initial;
    }

    .codeEditor {
        width: 100%;
        min-width: initial;
        max-width: 600px;
    }

    .questionName {
        margin-top: 100px;
        margin-bottom: 20px;
        border: 0;
    }

    .actionsButtonsContainer {
        display: flex;
        margin-left: 0;
        margin-right: 0;
        justify-content: space-between;
    }

    .sendBtn {
        width: 150px !important;
    }

    .questionDescription {
        font-size: 17px;
    }

}


$quickTestRadius: 8px;

.quickTestContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    color: white;
    background: #2a5273;
    border-radius: $quickTestRadius;


    input[type="text"] {
        margin-top: 20px;
        width: 90%;
        min-height: 40px;
        margin-left: 2.5%;
        border: none;
        outline: none;
        border-radius: 5px;
        overflow: hidden;
        background: rgba(39,40,32,255);
        color: white;
        letter-spacing: 0.52px;

        &:focus-visible {
            outline: 2px solid $main-color;
        }
    }
}

.questionListExam {
    gap: 14px;
}

.quickTestTitle {
    background: #2a465a;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: $quickTestRadius;
    border-top-right-radius: $quickTestRadius;


    span {
        background: $main-color;
        padding: 6px;
        font-size: 18px;
        font-weight: bold;
        border-top-left-radius: $quickTestRadius;
    }

    button {
        background: green;
        color: white;
        border: 0;
        font-weight: bold;
        padding-inline: 30px;
        font-size: 20px;
        border-top-right-radius: $quickTestRadius;
    }
}

.quickTestOutput {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;

    span:nth-of-type(1) {
        font-size: 18px;
        font-weight: bold;
    }

    span:nth-of-type(2) {
        font-size: 18px;
        font-weight: bold;
        margin-left: 10px;
        color: #04e517;
    }
}

.topRow > *:hover .toolTip {
    visibility: visible;
}

.toolTip {
    position: absolute;
    background: white;
    color: black;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 12px);
    z-index: 100;
    border-radius: 8px;
    padding: 10px;
    visibility: hidden;
    padding-inline: 14px;
    white-space: nowrap;
    width: fit-content;
    text-align: center;
    cursor: initial;
    user-select: none;
    font-size: 15px;
    font-weight: bold;
    box-shadow: 0 0 8px 5px rgba(black, 0.8);

    &::before {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        background: white;
        z-index: -1;
        left: 50%;
        top: 0;
        transform: translateX(-50%) translateY(-10%) rotate(45deg);
    }
}
