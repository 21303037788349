@mixin flexColumn {
    display: flex;
    flex-direction: column;
}

.popup {
    width: 500px;
    border: 0;
    animation: fadeIn 0.3s;
    border-radius: 15px;
    box-shadow: 2px 5px 6px 8px rgba(0, 0, 0, 0.5);
}

.container {
    @include flexColumn;
}

.container > img{
    width: 30px;
    margin-left: auto;
    cursor: pointer;
}

.title {
    font-size: 34px;
}

.subTitle {
    font-size: 22px;
    margin-top: 14px;
}

.subTitle > span {
    color: orange;
}

.form {
    @include flexColumn;
}

.form input {
    border-radius: 4px;
    height: 35px;
}

.form input[type="text"] {
    margin-top: 24px;
}
.form input[type="password"] {
    margin-top: 14px;
}

.form input {
    border: 1px solid #ccc;
    padding-left: 14px;
    font-size: 16px;
}

.error {
    color: red;
    font-weight: 750;
    text-align: center;
    font-size: 16px;
    position: relative;
    top: 8px;
}

.loginWithGoogle {
    flex-direction: row;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #ccc;
    justify-content: center;
    background: #3b3c3e !important;
    gap: 10px;
}

.loginWithGoogle img {
    width: 24px;
}

.actionContainer {
    @include flexColumn;
    gap: 10px;
    width: 100%;
    margin-top: 26px;
}

.actionContainer > button {
    height: 37px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-weight: bold;
    background: #416ee1;
    color: white;
}

.or {
    margin-inline: auto;
    font-weight: bold;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 100%;
    }
}
