@use "../../init/global" as *;

@mixin showResultScreen {
    width: 100%;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    color: white;

    h3 {
        font-size: 45px;
        margin-bottom: 20px;
    }

    .loadDesc {
        font-size: 24px;
        text-align: center;

    }

    .scoreText {
        margin-top: 15px;
        font-weight: 600;
        font-size: 32px;
        text-align: center;

    }
}


.wonLoadingScreen {
    background: dodgerblue;
    @include showResultScreen;

    img {
        margin-top: 30px;
        width: 330px;
        height: 330px;
    }
}

.lostLoadingScreen {
    background: #704817;
    @include showResultScreen;

    img {
        margin-top: 40px;
        width: 270px;
        height: 270px;
    }
}

.drawLoadingScreen {
    background: #63acf3;
    @include showResultScreen;

    img {
        margin-top: 40px;
        width: 270px;
        height: 270px;
    }
}


.gameSummary {
    display: flex;
   justify-content: center;

}


.resultContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min(700px, 100vw);
    padding-block: 30px;
    color: white;
    margin-top: 60px;
    min-height: calc(100vh - 90px);

    .coins {
        display: flex;
        align-items: center;
        margin: -20px 22px 20px auto;

        span {
            font-weight: 700;
            font-size: 24px;
        }
        img {
            width: 35px;
            height: 35px;
            margin-left: 10px;
        }
    }

    & > h3 {
        font-size: 38px;
        font-weight: 400;
        font-family: Game-Bold, serif;
        -webkit-text-stroke: 2px black;
        text-align: center;

        @include mobile {
            font-size: 30px;
        }
    }

    & > span {
        font-size: 24px;
        margin-top: 20px;
        font-weight: bold;
    }
}

.won {
    background: linear-gradient(#aa7b2e, #f5d26e, #974e0e);

    & > h3 {
        color: #00ff00;
    }
}

.lost {
    background: linear-gradient(#706b62, #6c5855, #483424);

    & > h3 {
        color: #f5ecdb;
    }
}

.draw {
    background: linear-gradient(#00a6ff, #314cab, #302d62);

    & > h3 {
        color: white;
    }
}


.playersSummaryContainer {
    display: flex;
    gap: 100px;
    margin-top: 100px;

    @include mobile {
        gap: 50px;
    }
}

.playerScoreContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-inline: auto;
    position: relative;

    img {
        width: 200px;
        height: 200px;
    }

    span {
        font-size: 26px;
        font-weight: 400;
        z-index: 100;
    }

    .crown {
        position: absolute;
        top: -120px;
        width: 180px;
        height: 180px;
    }

    svg {
        position: absolute;
        right: 140px;
        width: 100px;
        height: 180px;
    }

    @include mobile {

        margin-top: 30px;

        img {
            width: 100px;
            height: 100px;
        }

        span {
            font-size: 24px;
        }

        .crown {
            position: absolute;
            top: -110px;
            width: 110px;
            height: 140px;
        }

        svg {
            position: absolute;
            right: 65px;
            width: 70px;
            height: 180px;
            top: -35px;
        }
    }
}


.summaryBtn {
    width: 400px !important;

    @include mobile {
        width: 90% !important;
    }
    font-weight: bold;
}

.summaryBtn:nth-of-type(1) {
    margin-top: 50px;
    margin-bottom: 15px;

    @include mobile {
        margin-top: 80px;
    }
}

.bonuses {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    border: 3px solid black;
    background: gold;
    border-radius: 10px;
    padding: 2px;
    color: black;


    h3 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 20px;
        text-decoration: underline;
    }
}

.bonus {
    display: flex;
    font-weight: 500;
    font-size: 24px;
    padding: 8px;
}

.bonusDesc {
    margin-right: 32px;

    @include mobile {
        font-size: 24px;
        margin-right: 24px;
    }
}

.bonus img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}


