
.close {
    img {
        width: 30px;
        height: 30px;
    }
    margin-right: auto !important;

}

.title {
    font-weight: bold;
    font-size: 26px;
    border-bottom: 2px solid black;
}

.dialog {
    width: 509px;
    border-radius: 22px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.challengeContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
    margin-top: 32px;
    margin-bottom: 22px;
}

.challenge {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    div {
        display: flex;
        width: 100%;

        button {
            width: 100%;
            background-color: dodgerblue;
            border: 0;
            border-radius: 16px;
            color: white;
            font-weight: bold;
            font-size: 20px;

        }

        img {
            width: 60px;
            height: auto;
        }
    }

}

