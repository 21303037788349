@use "../../init/global" as *;


.upper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #3699c5;
    margin-top: var(--navigation-height);
    padding-top: 25px;
    padding-bottom: 25px;
}

.title {
    color: white;
    width: 50%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    ul {
        margin-top: 5px;
    }
    li:not(:last-of-type) {
        margin-bottom: 5px;
    }

    & > div {
        font-size: 18px;

        @include mobile {
            span:first-child {
                display: none;
            }
        }
    }

    @include mobile {
        justify-content: center;
        width: calc(100% - 10px);
        margin-left: 10px;
    }
}

.playersContainer {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 800px;
    max-width: 90%;
    margin-inline: auto;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding-bottom: 20px;

    @include mobile {
        max-width: 95%;
    }

    & > button {
        margin-top: 50px;
        width: 300px;
        margin-inline: auto;
        font-weight: bold;

    }

    & > span {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .playerRow {
        background: wheat;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 12px;
        padding-block: 3px;
        box-shadow: 0 0 10px rgba(black, 0);
        width: 75%;
        margin-inline: auto;
        margin-top: 10px;
        border-radius: 5px;
        border: 1.5px solid black;

        @include mobile {
            width: 88%;
            padding-inline: 10px;
            padding-block: 2px;
        }

        img {
            width: auto;
            height: 60px;

            @include mobile {
                height: 50px;
            }
        }

        .rightSide {
            gap: 5px;
        }

        & > div {
            display: flex;
            align-items: center;
            gap: 50px;

            span:first-child {
                font-size: 18px;
                font-weight: bold;
            }

            @include mobile {

                gap: 30px;

                span:nth-child(3) {
                    display: none;
                }
            }
        }
    }
}



.selectQuestionsNum {
    margin-top: 50px;

}

