$max-width: 870px;
$main-color: #2552b0;



:root {
  --accomplished-percentage: 0%;
}


@mixin center-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin mobile {
  @media (max-width: $max-width) {
    @content;
  }
}

.remove-link-style {
    text-decoration: none;
}

dialog::backdrop {
  background: rgba(black, 0.85);
}

.removeDefault {
  border: 0;
  background: inherit;
  padding: 0;
  margin: 0;
}


.hideMobile {
  @include mobile {
    display: none;
  }
}

.hideComputer {
    @media (min-width: $max-width) {
        display: none;
    }
}
