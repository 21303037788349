@use ".././init/global" as *;

:root {
    --item-width: 23vw;
    --item-gap: 30px;
    --item-height: 56px;
    --above-medium: 0;
    --above-hard: 0;
    --start-height: 790px;
    --mobile-gap: 100px;
}

.normalInput {
    height: 30px;
    border-radius: 10px;
    border: 1.5px solid #6772E54C;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 600;
}

.container {
    top: var(--start-height);
    position: absolute;
}

.container > div > span {
    font-weight: bold;
    position: relative;
    top: -30px;
    user-select: none;
}

.nameText {
    border: 0;
    padding: 0;
    background: inherit;
    cursor: pointer;
    transition: color 0.1s ease-in-out;
    font-family: Avenir, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    color: black;
}

.nameText:hover {
    color: #626ee3;
}

.easyCont {
    position: absolute;
    left: calc(25vw - var(--item-width) / 2 - var(--item-gap));
}

.mediumCont {
    position: absolute;
    left: calc(50vw - var(--item-width) / 2);
}

.hardCont {
    position: absolute;
    left: calc(75vw - var(--item-width) / 2 + var(--item-gap));
}

.easyCont > p, .mediumCont > p, .hardCont > p {
    width: 200px;
    color: black;
    margin-top: -45px;
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    font-size: 23px;
    font-weight: bold;
    user-select: none;
}

.question-item {
    height: 50px;
    min-width: var(--item-width);
    border-radius: 10px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    font-weight: 600;
    box-shadow: rgb(0, 0, 0, 0.25) 0 1px 2px 0;
    position: absolute;
    background: white;
}

.question-item > div:first-child {
    width: 70%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    user-select: none;
}

.rightContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.likeImg{
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
    user-select: none;
    filter: invert(92%) sepia(82%) saturate(3121%) hue-rotate(315deg) brightness(100%) contrast(103%);
}

.likeImg:hover {
    transform: scale(1.1);
}

.likeImg[src*="star2"] {
    fill: rgb(255, 220, 100);
    filter: initial;

}

.spacer {
    height: 100px;
    opacity: 0;
}

.on-drag {
    transition: transform 0.2s linear;
    transform: scale(1.08);
    box-shadow: 5px 2px 6px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
}

.not-on-drag {
    transition: top 0.6s ease 0s, transform 0.2s linear;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #6772E54C;
}

.circle-mark {
    background: #0cdc0c;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-mark::after {
    content: "\2713";
    color: #016e01;
}

.grab-color {
    height: 50px;
    width: 38px;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    touch-action: none;
}

.practice-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    background: linear-gradient(-90deg,#02203c,#001528);
    color: white;
    text-align: center;


    h1 {
        margin-top: 60px;
        font-size: 28px;
    }

    h2 {
        margin-top: 28px;
        font-size: 21px;
        font-weight: 500;
    }

    input[type="text"] {
        margin-top: 55px;
        padding: 10px 10px;
        width: 400px;
        user-select: none;
        margin-bottom: 72px;

        @include mobile {
            width: 85%;
        }
    }

    input[type="text"]:focus-visible {
        user-select: initial;
    }
}

.accomplishment-container {
    $primary-color: #626ee3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    text-align: center;

    & > span {
        font-size: 28px;
        font-weight: 460;

        @include mobile {
            font-size: 24px;
        }
    }

    & > div {
        margin-top: 30px;
        width: 500px;
        height: 40px;
        background: lightgray;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 20px;
        border-radius: 10px;
        position: relative;

        &::before {
            content: "";
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            transition: width 0.5s ease-in-out;
            width: var(--accomplished-percentage);
            height: 100%;
            background: $primary-color;
        }

        & > span {
            text-align: center;
            width: 100%;
            position: absolute;
        }

        @include mobile {
            width: 91%;
        }
    }

    & > button {
        background: $primary-color;
        color: white;
        border: 0;
        padding: 14px;
        border-radius: 14px;
        margin-top: 42px;
        font-weight: bold;
        font-size: 22px;

        @include mobile {
            font-size: 21px;
        }

    }
}

.color-easy {
    background-color: limegreen;
}

.color-medium {
    background-color: orange;
}

.color-hard {
    background-color: red;
}



@media (max-width: 960px) {
    :root {
        --item-width: 75vw;
        --start-height: 820px;
    }

    .filter-container {
        top: 165px;
        left: calc(50% - min(340px, 58vw) / 2);
        flex-direction: column;
        position: initial;
        margin-top: 40px;
    }

    .filter-container img, .filter-container button {
        display: none;
        position: initial;
    }

    .practice-title {
        font-size: 20px;
        margin-top: 110px;
    }

    .filter-question {
        width: min(340px, 58vw);
    }

    .container > div > span {
        display: none;
    }

    .easyCont {
        left: calc(50vw - var(--item-width) / 2);
    }

    .mediumCont {
        top: calc(var(--above-medium) * var(--item-height)  + var(--mobile-gap));
        left: calc(50vw - var(--item-width) / 2);
    }

    .hardCont {
        top: calc(var(--above-hard) * var(--item-height) + var(--mobile-gap)*2);
        left: calc(50vw - var(--item-width) / 2);
    }
}


@media (max-width: 600px) {
    :root {
        --item-width: 87vw;
    }
}



