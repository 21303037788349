@use "../../init/global" as *;


.dialog {
    width: 509px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    background: #1e2d77;
    color: white;
    min-height: 300px;
    justify-content: center;

}



.close {
    border: 0;
    padding: 0;
    background: inherit;
    margin-right: auto;

    img {
        width: 30px;
        height: 30px;
    }
}


























.sampleQuestion {
    margin-right: 10%;
    margin-top: -3%;
    width: 400px;
    background: #0a1859;
    border-radius: 5px;
    margin-bottom: 50px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

    @include mobile {
        display: none;
    }
}

.sampleQuestionTitle {
    height: 45px;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #07264b;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sampleQuestionTitle > button {
    height: 100%;
    width: 100px;
    background: green;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
}

.sampleQuestionTitle > h4 {
    margin-left: 15px;
    font-size: 20px;
}

.sampleQuestionBody {
    width: 100%;
    height: 300px;
    overflow: hidden;
}

.questionDesc {
    margin-left: 10px;
}

.loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 21px;
    margin-top: -10px;
}


.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid #3d73e7;
    animation: spinner-anim 0.8s infinite linear alternate,
    spinner-anim1 1.6s infinite linear;
}

@mixin loading-state($color) {
    border: 9px solid $color;
    display: flex;
    animation: initial;
    justify-content: center;
}

@mixin loading-sign {
    font-size: 50px;
    font-weight: bold;
}

.loadingSuccess button {
    border: 0;
    background: none;
    color: #f1a1a1;
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 6px;
}


.loadingError button {
    border: 0;
    background: none;
    color: lightblue;
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 6px;
}

.loadingError > span {
    text-align: center;
    font-size: 18px;
}

.loadingError > div:nth-of-type(1) {
    @include loading-state(red);
}

.loadingError > div:nth-of-type(1):after {
    @include loading-sign;
    content: "x";
    color: red;
    margin-top: -6px;
}

.loadingSuccess div {
    @include loading-state(green);
}

.loadingSuccess div:after {
    @include loading-sign;
    content: "\2713";
    color: green;
    margin-top: -3px;
    margin-left: -6px;
}

.loadingSuccess span {
    font-weight: bold;
    font-size: 18px;
}


@keyframes spinner-anim {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
}

@keyframes spinner-anim1 {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
}


.testCaseContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.testCaseContainer > div {
    display: flex;
    gap: 15px;
}

.testCaseTitle {
    font-weight: bold;
    min-width: 100px;
}

.testCaseValue {
    box-sizing: border-box;
    padding-inline: 10px;
    font-weight: bold;
    min-width: 150px;
    width: 100%;
    border: 2px solid #0a1859;
    word-break: break-all;
}
