body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--background)
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --navigation-height: 60px;
    --background: white;
}

button {
    cursor: pointer;
}


.center-text {
    width: 100%;
    text-align: center;
}

.white {
    color: white;
}

.no-break {
    white-space: pre-wrap;
}

.sigma-button {
    background: #1c98f8;
    padding: 12px;
    border-radius: 5px;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.1s ease-in;
    font-size: 18px;
    width: 190px;
    display: flex;
    justify-content: center;
    border: none;
}

.sigma-button:hover {
    background-color: #1d89f6;
}


.sigma-button:disabled {
    background-color: #84b4e5;
}

@font-face {
    font-family: Game-Bold;
    src: url(/public/GameBold.otf);
}


