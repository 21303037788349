@use "../init/global" as *;


.home-content {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.google-play-button {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 150px;
    height: auto;
    cursor: pointer;
}

.buttons-home-screen {
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    gap: 150px;
}

h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
}

.modal {
    animation: fadeIn 0.5s;
    border-radius: 15px;
    border: none;
    box-shadow: 2px 5px 10px 8px rgba(0, 0, 0, 0.5);
    width: 500px;
}

:is(.modal, .modal1) button {
    padding: 12px;
    border-radius: 10px;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.1s ease-in;
    font-size: 18px;
    width: 190px;
    display: flex;
    justify-content: center;
    border: none;
}

.modal1 {
    animation: fadeIn 0.5s;
    border-radius: 15px;
    border: none;
    box-shadow: 2px 5px 10px 8px rgba(0, 0, 0, 0.5);
    width: 600px;
}


.quick-play-container button:nth-of-type(1) {
    background: green;
}
.quick-play-container button:nth-of-type(2) {
    background: orange;
}
.quick-play-container button:nth-of-type(3) {
    background: red;
}


.container-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.quick-play-container button {
    width: 100%;
}

.modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-bottom: 13px;
    border-bottom: 2px solid #333;
}

.modal-header > img {
    margin-right: auto;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.modal-header h3 {
    margin-right: auto;
    font-size: 25px;
}



.quick-play-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 5px;
    margin-top: 25px;
}

.quick-play-container h4 {
    font-size: 19px;
    margin-bottom: 16px;
    color: #333;
}

.match-outside-container {
    width: 90%;
}

.match-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.match-container > div {
    @include center-flex;
}


.match-container > div > span {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 25px;
}

.match-container > div > img {
    width: 100px;
    height: 100px;
}

.match-container > img {
    width: 140px;
    height: 140px;
    margin-top: 10px;
    animation: vs 1.5s linear infinite;
    filter: brightness(1.1);
}

@keyframes vs {
    0% {

    }
    50% {
        transform: scale(1.13);
    }
    100% {
    }
}

.points-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    gap: 8px;
}

.points-container img {
    width: 30px;
    height: 30px;
}

.description {
    color: white;
    font-size: 18px;
    margin-top: 50px;
}

.multi-play-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
}

.multi-play-container > span {
    margin-top: 30px;
    width: 100%;
    font-weight: bold;
    margin-bottom: 10px;
}

.multi-play-container input[type="text"] {
    width: 95%;
    height: 50px;
    background: wheat;
    text-align: left;
}

.multi-play-buttons-container {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 7px;
    margin-bottom: 20px;
}

.multi-play-buttons-container button {
    width: 160px;
    height: 50px;
    background: #363b45;
    display: initial;
}

.waiting-room {
    width: 100%;
    @include center-flex;
}

.players-list {
    background: wheat;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.waiting-room > span {
    font-size: 20px;
    margin-top: 15px;
}

.player-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-inline: auto;
    height: 60px;
}

.player-item img {
    width: 45px;
    height: 45px;
}

.player-item-index {
    font-size: 18px;
    font-weight: bold;
}

.player-item-name {
    font-size: 19px;
    font-weight: bold;
}


.question-num {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.question-num label {
    font-weight: bold;
}

.question-num input[type="radio"]{
    width: 25px;
    height: 25px;
    accent-color: #232323;
    cursor: pointer;
    transition: transform 0.1s ease-in;
}

.question-num input[type="radio"]:hover{
    transform: scale(1.1);
}

.question-num > div {
    @include center-flex;
}

@mixin revertTextAndBackground($revert) {
    @if $revert {
        background: white;
        color: black;
    } @else {
        background: black;
        color: white;
    }
}

.waiting-room > button {
    width: 40%;
    height: 55px;
    @include revertTextAndBackground(true);
    font-weight: 800;
    margin-top: 30px;
    display: initial;
    border: 3px solid black;
    font-size: 21px;
}

.waiting-room > button:hover {
    @include revertTextAndBackground(false);
}

.invite-container {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.home-upper {
    padding-top: 150px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: $main-color;
    color: white;

    @include mobile {
        flex-direction: column;
        padding-bottom: 50px;
        padding-top: 100px;
    }
}

.home-title {
    color: white;
    width: 50%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    & > p {
        font-size: 18px;
        @include mobile {
            text-align: center;
        }
    }

    @include mobile {
        align-items: center;
        justify-content: center;
        width: calc(100% - 10px);
        margin-left: 10px;
    }

}

.what-is-sigma {
    margin-top: 80px;
    text-align: center;
    font-weight: 500;
    font-size: 35px;

    @include mobile {
        margin-top: 60px;
        margin-bottom: -30px;
        font-size: 32px;
        font-weight: bold;
    }
}

.about-sigma {
    margin-top: 40px;
    max-width: 90vw;
    margin-inline: auto;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-evenly;

    @include mobile {
        margin-top: 60px;
    }
}

.box {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out box-shadow;
    flex-shrink: 0;
    border-radius: 5px;
    background: white;
    border: 0;
}


.box:hover, .box:focus-visible {
    outline: 0;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);

    svg {
        transform: scale(1.15);
    }
}

.box > svg {
    width: 50px;
    margin: 10px 0;
    // transition for 2 properties: transform and margin
    transition: transform 0.3s ease-in-out;
}

.box > p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 10px;
    color: #595757;
}

.box > span {
    color: #1c98f8;
    cursor: pointer;
}

.line-separating {
    width: 85%;
    height: 1.5px;
    background: #595757;
    margin: 100px 0;
    margin-inline: auto;

    @include mobile {
        width: 90%;
    }
}

.details {
    margin-inline: auto;
    gap: 50px;
    width: 80%;

    @include mobile {
        width: 94%;
    }
}

.details-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2 {
        font-size: 32px;

        @include mobile {
            font-size: 26px;
        }
    }
}


.details-title > span {
    font-size: 20px;
    margin-top: 20px;
}

.details-list {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.details-list > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.details-list > div > span {
    font-size: 18px;
    font-weight: bold;
}

.details-list > div > span > span {
    font-size: 18px;
    font-weight: normal;
    color: #464545;
}

.details-list  {
    svg {
        width: 50px;

        @include mobile {
            min-width: 43px;
            max-width: 43px;
        }
    }
}


.modal-container-body {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 60px;

    img {
        width: 120px;
        height: 120px;
    }

    button {
        border: 0;
        background: inherit;
        box-shadow: none;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 190px;
        padding-block: 10px;

        transition: background 0.15s ease-in-out;
        cursor: pointer;

        &:hover {
            background: lightblue;
        }
    }

    span {
        font-size: 20px;
        font-weight: bold;
    }


    @media (max-width: 400px){
        justify-content: space-between;
        gap: initial;

        img {
            width: 100px;
            height: 100px;
        }
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

@media (max-width: $max-width) {

    .waiting-room > button {
        width: 70%;
    }
}
