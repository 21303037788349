@use "../../init/global" as *;


.container {
    border: 0;
    border-radius: 30px;
    background: saddlebrown;
    color: white;
    padding-top: 40px;
    padding-bottom: 60px;
    padding-inline: 30px;
    animation: show 0.5s ease-in-out forwards;

    @include mobile {
        padding: 0;
        width: 100%;
        height: 80vh;
    }
}

@keyframes show {
    from {
        transform: scale(0.3);
    }
    to {
        transform: scale(1);
    }
}



.matchScreen {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 15px;

    @include mobile {
        gap: 0;
        justify-content: space-between;
    }
}

.matchScreen > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline: auto;
}

.flag {
    font-size: 64px;
}

.profile {
    width: 100px;
    height: 100px;
}

.name {
    font-size: 24px;
    font-weight: bold;
    width: 160px;
    text-align: center;
    margin-top: 40px;

    @include mobile {
        width: initial;
        font-size: 21px;
    }
}

.coinsContainer span, .rateContainer span {
    font-size: 22px;

    @include mobile {
        font-size: 20px;
    }
}

.coinsContainer, .rateContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 20px;

    img {
        width: 40px;
        height: 40px;
    }
}


.vs {
    height: 250px;
    animation: vs 2s ease-in-out infinite;

    @include mobile {
        display: none;
    }
}

.vsLine {
    flex-direction: column;
    display: none !important;

    div {
        width: 2px;
        height: 200px;
        background: black;
    }

    img {
        display: initial;
        width: 80px;
        margin-top: -5px;
        margin-bottom: -11px;
    }

    @include mobile {
        display: flex !important;
    }
}


@keyframes vs {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}
