
.pieGraph {
    width: 300px;
    height: 300px;
}

.lineGraph {
    width: 80%;
}

.lineGraph, .pieGraph {
    display: flex;
    flex-direction: column;
    span {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        text-decoration: underline;
        margin-bottom: 16px;
    }
}

.statistics {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.lineGraphsContainer, .pieGraphsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.pieGraphsContainer {
    margin-top: 24px;
}

.lineGraphsContainer {
    margin-top: 60px;
}
